<template>
  <div>
    <b-card-actions title="Buscar Historial" action-collapse>
      <b-card-text>
        <b-row>
          <b-col cols="12" class="mb-2">
            <b-alert variant="dark" show>
              <div class="alert-body">
                <span>Selecciona los criterios de filtrado y haz click en buscar.</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-initialDate" label="Fecha inicial">
              <b-form-input
                id="i-initialDate"
                type="date"
                placeholder=""
                v-model="formSearchData.initialDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-finalDate" label="Fecha final">
              <b-form-input
                id="i-finalDate"
                type="date"
                placeholder=""
                v-model="formSearchData.finalDate"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="HAWB" label-for="i-status">
              <b-form-select
                :options="optionsProceso"
                v-model="formSearchData.proceso"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tracking" label-for="i-retained">
              <b-form-input
                id="htracking"
                type="text"
                placeholder=""
                v-model="formSearchData.tracking"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label-for="i-initialDate"
              label="Nombre cliente/Destinario"
            >
              <b-form-input
                id="hcliente"
                type="text"
                placeholder=""
                v-model="formSearchData.strSearch"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="offset-md-10" align="right">
            <b-button variant="primary" @click="searchHistorial">Buscar</b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-actions>
    <b-card>
      <!--<b-table striped hover :items="items" :fields="fields"></b-table>-->
      <div>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group label-for="i-perPage" label="Items por página" label-cols="7">
              <b-form-input
                id="i-perPage"
                type="number"
                placeholder=""
                v-model="perPage"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                align="right"
                aria-controls="my-table"
            ></b-pagination>
            <!-- <p class="mt-3">Página actual: {{ currentPage }}</p> -->
          </b-col>
        </b-row>
        <br />
        <b-table
          striped
          hover
          responsive
          id="my-table"
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(Rastrear)="row">
            <b-button variant="primary" @click="onRastrearClick(row)" size="sm">
              Rastrear
            </b-button>
          </template>

          <template #cell(Imagen)="row">
            <b-button variant="warning" size="sm"> Ver </b-button>
          </template>

          <template #cell(EstadoActual)="data" size="sm">
            <b-badge variant="success">
              {{ data.value }}
            </b-badge>
          </template>


          <template #cell(Tracking)="data">
            <b-badge variant="warning">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(HAWB)="data">
            <b-badge variant="info">
              {{ data.value }}
            </b-badge>
          </template>

        </b-table>
        <br />
      </div>
    </b-card>
    <b-modal
      v-model="showModalRastreo"
      ok-title="Cerrar"
      id="modalEditRecipient"
      size="lg"
      title="Trackings"
      ok-only
      @ok="showModalRastreo = false"
    >
      <b-table
        id="tableTracking"
        :items="itemsTracking"
        :fields="fieldsTracking"
        striped
        small
        table-variant="secondary"
        caption-top
      >
        <template #table-caption>
          <h5><b>TRACKINGS:</b></h5>
          <h5>
            <b>HAWB: {{ hawb }}</b>
          </h5>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BButton,
  BTable,
  BPagination,
} from "bootstrap-vue";
export default {
  components: {
    BCardActions,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BButton,
    BTable,
    BPagination,
    vSelect,
  },
  data() {
    return {
      showModalRastreo: false,
      perPage: 10,
      currentPage: 1,
      hawb: "",
      formSearchData: {
        initialDate: "",
        finalDate: "",
        proceso: "TRANSITO",
        strSearch: "",
      },
      optionsProceso: [
        { text: "TRANSITO", value: "TRANSITO" },
        { text: "ENTREGADA", value: "ENTREGADA" },
        { text: "SIN SOLICITUD", value: "SIN SOLICITUD" },
      ],
      fields: [
        "Rastrear",
        "Imagen",
        "PruebaEntrega",
        "EstadoActual",
        "FechaCreación",
        "Destinatario",
        "Tracking",
        "HAWB",
        "HawbAgrupa",
        "Contenido",
        "PesoLbs",
        "PesoKgs",
        "Alto",
        "Ancho",
        "Largo",
      ],
      items: [],
      fieldsTracking: ["Fecha", "Estado", "Observacion"],
      itemsTracking: [],
    };
  },
  created() {},
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    searchHistorial() {
      let str = `Bearer ${this.$session.get("userData").access_token}`;
      this.$http
        .post(
          `api/casillero/clientes/historial/busqueda?page=${this.currentPage}`,
          {
            pageSize: this.perPage,
            tipo_proceso: this.formSearchData.proceso,
            str_busqueda: this.formSearchData.strSearch,
            fecha_inicial: this.formSearchData.initialDate,
            fecha_final: this.formSearchData.finalDate,
          },
          { headers: { Authorization: str } }
        )
        .then((response) => {
          // console.log(response.data);
          this.formatDataforTable(response.data.datos.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataforTable(dataFromService) {
      this.items = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {};
        itemFormated = {
          PruebaEntrega: "",
          EstadoActual: itemFromService.estados_de_guia_descripcion,
          FechaCreación: itemFromService.created_at,
          Destinatario: itemFromService.razon_social,
          Tracking: itemFromService.tracking_tienda_casillero,
          HAWB: itemFromService.hawb,
          HAWBAGRUPA: "",
          Contenido: itemFromService.contenido,
          PesoLbs: itemFromService.peso_kg,
          PesoKgs: itemFromService.peso_lb,
          Alto: "",
          Ancho: "",
          Largo: "",
          id: itemFromService.id,
        };
        formatedData.push(itemFormated);
        this.items = formatedData;
      });
    },
    onRastrearClick(row) {
      console.log(row.item);
      this.hawb = row.item.HAWB;
      let packageId = row.item.id;
      this.$http
        .get(`api/casillero/historial/rastrear/${packageId}`, {
          headers: {
            Authorization: `Bearer ${
              this.$session.get("userData").access_token
            }`,
          },
        })
        .then((response) => {
          // console.log(response);
          this.formatDataTracking(response.data.datos.trackings);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataTracking(dataFromService) {
      this.itemsTracking = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {};
        itemFormated = {
          Fecha: itemFromService.fecha_hora,
          Estado: itemFromService.estado_de_guia.descripcion,
          Observacion: itemFromService.obsservacion,
        };
        formatedData.push(itemFormated);
      });
      this.itemsTracking = formatedData;
      this.showModalRastreo = true;
    },
  },
  beforeMount() {
    this.searchHistorial();
  },
};
</script>

<style>
</style>
